<template>
  <div>
    <div class="subheader mb-2">{{ label }}</div>
    <b-form-datepicker
      v-model="computedSetDate"
      class="mb-2"
      :format="'YYYY-MM-DD'"
      :state="isValid"
      @input="dateSelected($event)"
      :required="required"
      :min="min"
      :max="max"  
      locale="en"
    >
    </b-form-datepicker>
  </div>
</template>


<script>

import { add } from "date-fns";

export default {
  name: "DateSelect",
  data: () => {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const newDate = add(new Date(new Date().getFullYear(), 0, 1), {years: -3 })
    let year = new Date(newDate).getFullYear();
    let month = (1 + new Date(newDate).getMonth())
      .toString()
      .padStart(2, "0");
    let day = new Date(newDate).getDate().toString().padStart(2, "0");
    const minDate = year  + "-" + month  + "-" + day;
    const maxDate = new Date(today)
    return {
      selected: "",
      random: "", 
      min: minDate,
      max: maxDate
    };
  },
  props: {
    label: { default: "Choose A Date" },
    setdate: { default: "" },
    errormessage: { default: "Please enter a date" },
    required: { default: false }
  },
  methods: {
    dateSelected(event) {
      this.selected = event;
      this.$emit("update:selected", this.selected);
    },
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },
  },
  computed: {
    computedSetDate: {
      get() {
        return this.setdate;
      },
      set(value) {
        this.selected = value;
      },
    },
    isValid() {
      if (this.required) {
        return this.selected.length > 0 || this.setdate.length > 0;
      } else {
        return true;
      }
    },
  },
};
</script>
